html {
	scroll-behavior: smooth;
}

.ReactModal__Content {
	margin-top: 4em;
}

.latir-logo {
  animation: pulse 1.5s infinite;
}

#pen-angled {
	fill: #222222;
	animation: writing-pen 0.2s infinite alternate;
	transform-origin: center bottom;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

.animate-fadeIn {
  animation: fadeIn 0.3s ease forwards;
}

.animate-bounce {
  animation: bounce 3s infinite;
}

@keyframes writing-pen {
	0% {
		transform: translateY(0) rotate(0deg);
	}
	50% {
		transform: translateY(0px) rotate(5deg);
	}
	100% {
		transform: translateY(0px) rotate(-4deg);
	}
}

@keyframes pulse {
	0%{
		transform:scale(1);
	}
  20%{
    transform:scale(1.2);
    filter: drop-shadow(0 0 34px #00496B);
  }
	40%{
		transform:scale(1);
    filter: drop-shadow(0 0 0 #00496B);
	}
	60%{
    transform:scale(1.2);
    filter: drop-shadow(0 0 34px #00496B);
	}
	80%{
		transform:scale(1);
	}
  100%{
		transform:scale(1);
  }
}